.App {
  text-align: center;
}

* {
  box-sizing: border-box;
  /* Applies border-box to all elements */
}

body {
  min-width: 400px;
  /* You can adjust the value to your desired minimum width */
}

header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  background: linear-gradient(180deg, rgba(25, 33, 52, 1) 0%, rgba(7, 8, 21, 1) 100%);
  color: white;
}

.logo {
  width: 40px;
  height: 40px;
  /* padding-right: 5px; */
}

.eth {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}

.link {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.link:hover {
  color: #ffcc00;
  /* Example hover color */
}

.leftH {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rightH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.headerItem {
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.headerItem:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.menu-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: white;
}

@media (max-width: 866px) {
  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(25, 33, 52, 1) 0%, rgba(7, 8, 21, 1) 100%);
    padding: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 99;
  }

  .nav-links.open {
    display: flex;
  }

  .headerItem {
    padding: 8px 12px;
  }

  .menu-icon {
    display: block;
  }

  .rightH {
    /* justify-content: space-between; */
    width: 100%;
  }

  .leftH {
    /* flex-direction: column; */
    width: 100%;
  }
}

@media (max-width: 480px) {
  .leftH {
    gap: 10px;
  }

  .headerItem {
    font-size: 14px;
    padding: 6px 10px;
  }

  .logo {
    width: 50px;
    /* Adjust logo size for mobile */
  }
}

@media (min-width: 866px) {
  .nav-links {
    display: flex;
    flex-direction: row;
    gap: 20px;
    z-index: 99;
  }
}

.connectButton {
  background-color: #243056;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 100px;
  color: #5981F3;
  font-weight: bold;
  transition: 0.3s;
}

.connectButton:hover {
  cursor: pointer;
  color: #3b4874;
}

.mainWindow {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.mainWindow.swapMargin {
  margin-top: 10px !important;
  /* Override the top margin for the Swap route */
}

.tradeBox {
  flex: 0 0 35%;
  max-width: 100%;
  /* Prevents it from growing too wide */
  min-width: 400px;
  /* Ensures it doesn’t shrink too small */
  min-height: 400px;
  /* Prevents it from growing beyond 400px */
  background-color: #0E111B;
  /* Existing styles */
  border: 2px solid #21273a;
  border-radius: 15px;
  padding: 20px;
  /* Adds padding inside the box */
}

.tradeBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.cog {
  color: #51586f;
  transition: 0.3s;
}

.cog:hover {
  color: white;
  rotate: 90deg;
  cursor: pointer;
}

.switchButton {
  background-color: #3a4157;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  position: absolute;
  top: 86px;
  left: 180px;
  color: #5F6783;
  border: 3px solid #0E111B;
  font-size: 12px;
  transition: 0.3s;
}

.dollarAmount {
  position: absolute;
  top: 75px;
  left: 14px;
  font-size: 10px;
  color: #5F6783;
}

.switchButton:hover {
  color: white;
  cursor: pointer;
}

.inputs {
  position: relative;
}

.assetOne {
  position: absolute;
  width: 100px;
  height: 30px;
  background-color: #3a4157;
  top: 36px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetTwo {
  position: absolute;
  width: 100px;
  height: 30px;
  background-color: #3a4157;
  top: 175px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetTwoBuy {
  position: absolute;
  width: 100px;
  height: 30px;
  background-color: #3a4157;
  top: 175px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetOne:hover {
  cursor: pointer;
}

.assetTwo:hover {
  cursor: pointer;
}

.assetLogo {
  height: 22px;
  margin-left: 5px;
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #243056;
  width: calc(100%);
  height: 55px;
  font-size: 20px;
  border-radius: 12px;
  color: #5981F3;
  font-weight: bold;
  transition: 0.3s;
  margin-bottom: 30px;
  margin-top: 8px;
}

.Button[disabled] {
  background-color: #243056;
  opacity: 0.4;
  color: #5982f39b;
}

.Button[disabled]:hover {
  cursor: not-allowed;
  background-color: #243056;
}

.Button:hover {
  cursor: pointer;
  background-color: #3b4874;
}

.tokenLogo {
  height: 40px;
  width: 40px;
}

.modalContent {
  border-top: 1px solid #363e54;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tokenChoice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tokenChoice:hover {
  cursor: pointer;
  background-color: #1f2639;
}

.tokenName {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tokenTicker {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #51596f;
}

.pulsating-img-container {
  position: relative;
  display: inline-block;
}

.pulsating-img-container::after {
  content: "";
  display: block;
  position: absolute;
  width: 200px;
  height: 300px;
  top: 0;
  left: 0;
  border-radius: 10px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

/* src/LotteryCard.css */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: linear-gradient(180deg, rgba(25, 33, 52, 1) 28%, rgba(7, 8, 21, 1) 75%);
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  margin: 20px auto;
  width: 100%;
  max-width: 600px;
}

@media (min-width: 768px) {
  .card {
    padding: 70px;
    /* Larger padding for desktop */
  }
}

@media (max-width: 767px) {
  .card {
    padding: 16px;
    /* Smaller padding for mobile */
  }
}

.entry-amount,
.Button {
  width: 50%;
  text-align: center;
  margin: 20px auto;
}

.status {
  font-size: 18px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.status.active {
  background-color: #dff0d8;
  color: #3c763d;
}

.status.inactive {
  background-color: #f2dede;
  color: #a94442;
}

.entry-amount,
.tokens-needed {
  font-size: 16px;
  margin-bottom: 4px;
}

.entry-button {
  margin: 5px;
}

.referrer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}

.no-spinner {
  /* Hide the arrows for number inputs in WebKit browsers */
  -webkit-appearance: none;

}

/* Hide the arrows for number inputs in Firefox */
.no-spinner {
  -moz-appearance: textfield;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-container {
  position: relative;
}

.balance-text {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.8em;
  color: #fff;
}

.input-box {
  padding-right: 120px;
  /* Adjust padding to fit the buttons */
}

.no-spinner.custom-height {
  height: 40px !important;
  line-height: 40px !important;
  /* Ensure the line height matches the height */
}

/* .input-buttons {
  position: absolute;
  bottom: 5px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
} */

.input-buttons img.logo {
  order: 2;
  /* Ensures the logo stays between the balance and buttons */
}

.input-buttons button {
  background-color: #243056;
  color: #5981F3;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  cursor: pointer;
  font-size: 0.8em;
  margin-bottom: 10px;
  margin-left: 10px;
}

.input-buttons button:hover {
  background-color: #3b4874;
}

.referral-link-section {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
  /* Added bottom margin */
  width: 100%;
  /* Ensures the section takes full width */
}

.referral-link-label {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.referral-link-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  /* Ensures the container takes full width */
}

.referral-link {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  /* Adjust as needed */
  margin: 0 auto;
  /* Centers the referral link */
}

.copy-icon {
  margin-left: 5px;
  cursor: pointer;
}



.swapBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}


.mainContent {
  display: flex;
  /* Flex layout for horizontal alignment */
  /* Pushes items apart */
  align-items: top;
  /* Ensures items align to the same height if possible */
  gap: 5px;
  /* Adds spacing between the chart and trade box */
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
  /* Allows items to wrap on smaller screens */
}

.dexScreenerChart {
  flex: 1;
  /* Allows the chart to grow and take available space */
  /* Limits the chart width to 60% */
  min-width: 300px;
  min-height: 500px;
  /* Prevents the chart from shrinking too small */
  height: auto;
  /* Chart height adjusts with content */
  background-color: #0E111B;
  /* Optional background */
  border: 2px solid #21273a;
  border-radius: 15px;
  overflow: auto;
  /* Ensures iframe content doesn't overflow */

}

.dexScreenerChart iframe {
  width: 100%;
  height: 100%;
  /* Matches the parent container height */
}

.swapBox {
  flex: 0 0 25%;
  /* Fixes the trade box width to 35% */
  max-width: 100%;
  /* Prevents it from growing too wide */
  min-width: 300px;
  /* Ensures it doesn’t shrink too small */
  min-height: 400px;
  /* Trade box height adjusts with content */
  background-color: #0E111B;
  border: 2px solid #21273a;
  border-radius: 15px;
  padding: 20px;

}

/* Responsive Design */
@media (max-width: 866px) {
  .mainContent {
    flex-direction: column;
  }

  .swapBox {
    order: -1;
    /* Moves trade box to the top */
    max-width: 100%;
    /* Makes it full width */
    flex: unset;
    /* max-width: 100%;

    min-width: 300px;

    min-height: 400px; */
  }

  .dexScreenerChart {
    order: 0;
    width: 100%;
  }


}